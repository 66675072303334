.socialLoginContainer {
  margin-top: 3rem;

  p:first-of-type {
    margin-bottom: 1rem;
  }
}

.socialBtns {
  display: grid;
  grid-template-columns: repeat(var(--numOfSocialBtns), 1fr);
  gap: 1rem;
  align-items: center;
  justify-content: center;

  button {
    min-width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}
