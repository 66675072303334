.passwordInput {
  width: 100%;
  height: var(--field-height);
  position: relative;

  svg {
    position: absolute;
    right: 10px;
    top: 15px;
    height: 20px;
    width: 25px;
    color: var(--neutral-pure);
    cursor: pointer;
  }
}
