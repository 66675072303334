.loginContainer {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.loginContent {
  display: flex;
  flex-direction: column;
  flex: 1;

  align-items: center;
  justify-content: center;

  padding: 0;
  margin: auto;

  & > div:first-child {
    width: 100%;
    max-width: 416px;
    padding: 1rem;
  }

  img:first-of-type {
    margin: 0 auto !important;
    display: none !important;
  }

  h2 {
    color: var(--gray-8);
  }
}

@media (max-width: 767px) {
  .loginContent {
    .logo {
      display: flex;
      justify-content: center;
    }
    img:first-of-type {
      height: 4.5rem;
      display: inline !important;
    }

    h2 {
      text-align: center;
    }
  }
}
