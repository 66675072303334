.hrOr {
  overflow: visible;
  padding: 0;
  border: none;
  border-top: 1px solid #e0e0e0;
  color: var(--gray-5);
  text-align: center;
  margin: 3rem auto 2rem auto;

  &:after {
    content: "Ou";
    display: inline-block;
    position: relative;
    top: -12px;
    font: normal 14px/14px;
    padding: 0 16px;
    background: #fff;
  }
}
