.sideBar {
  width: calc(100% / 3);
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  background: var(--secondary-color-7);

  header {
    padding: 64px 64px 10px;

    a {
      display: block;
    }
  }

  h5 {
    color: white;
    padding: 30px 30px 30px 64px;
    font-weight: 300;
  }

  img:first-of-type {
    height: 3rem;
  }

  .illustration {
    padding: 0 0 5vh;

    div:first-of-type {
      width: 100% !important;
    }
  }

  span {
    color: var(--white);
    font-size: 0.7rem;
    text-align: center;
    padding-bottom: 5px;

    a {
      text-decoration: underline;
    }
  }
}

/* responsible */
@media (max-width: 1023px) {
  .sideBar {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .sideBar {
    display: none;
  }
}
