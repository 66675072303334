.stylizedLink {
  width: 100%;
  margin-bottom: 1rem;

  a {
    color: #007bff;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }
}
